















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import StudentInformationStatus from '@/components/organisms/StudentInformationStatus.vue'
import { StudentGoal } from '@/models/api/studentGoal'
import Correct from '@/components/modules/drills/organisms/Correct.vue'
import DrillWebApi from '@/mixins/drills/WebApi'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    StudentInformationStatus,
    Correct,
    TeacherHistoryBottomPanel,
    ButtonBase,
  },
})
export default class DrillResult extends Mixins(DrillWebApi) {
  @Prop({ default: 0 })
  resultDrillId!: number

  private isLoaded = false

  private studentData: {
    id?: string
    nickname?: string
    code?: string
    iconUrl?: string
    school?: string
    grade?: string
    userId?: number
    classModeCode?: string
    status?: { type: string; message: string }[]
    goal?: StudentGoal | null
  } = {}

  private classCategoryCode = ''

  private duration = 0

  private get correctParams() {
    return { currentPageType: 'result_all', classCategoryCode: this.classCategoryCode }
  }

  private get classCategoryText() {
    const classCategorys = { EN: '演習', SY: '宿題', RK: '理解度テスト' }
    return classCategorys[this.classCategoryCode]
  }

  private get durationText() {
    return `${Math.floor(this.duration / 60)}分`
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$loading.start()
    await this.startResultAll(this.resultDrillId)
    this.isLoaded = true
    Vue.prototype.$loading.complete()
  }

  protected async startResultAll(resultDrillId: number): Promise<any> {
    this.$store.commit('drills/setProcessed', false)
    const params = { resultDrillId: resultDrillId, isOnlyShow: true }

    // Web APIを投げて正誤入力に必要な情報を取得する
    let result: any
    try {
      result = await Vue.prototype.$http.httpWithToken.post('/drill/result-all-start', params)
    } catch {
      throw 'Drill Error!'
    }
    this.setSubjectCode(result.data.subjectCode)
    this.classCategoryCode = result.data.classCategoryCode
    this.duration = result.data.duration

    // ドリルデータをストアに格納する
    this.$store.dispatch('drills/setDrillData', result.data)

    // ドリルに関連する画像を取得する
    await this.imagePaths()

    // 読み込み完了
    this.$store.commit('drills/setProcessed', true)
  }

  private async setSubjectCode(code: string) {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['subjectCode'] = code
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private async movePrev() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const id = Array.isArray(this.$route.query.prevId)
      ? this.$route.query.prevId[0] || ''
      : this.$route.query.prevId || ''
    const learningType = Array.isArray(this.$route.query.prevType)
      ? this.$route.query.prevType[0] || ''
      : this.$route.query.prevType || ''
    const prevPage = Array.isArray(this.$route.query.beforePage)
      ? this.$route.query.beforePage[0] || ''
      : this.$route.query.beforePage || ''

    const targetQueryKeys = ['selectedSubject', 'grade', 'subject', 'unit', 'targetRadio', 'targetSelect', 'modeCode']
    const targetQuery = {}
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery[key] = value
    }
    if (prevPage) targetQuery['prevPage'] = prevPage

    const route = { name: name }
    if (id && learningType) {
      route['params'] = { id: id, learningType: learningType }
    }
    if (Object.keys(targetQuery).length > 0) route['query'] = targetQuery

    this.$router.push(route)
  }
}
